import "./Main.css"
import {useContext, useEffect, useState} from "react";
// import MainImageVertical from "../assets/image/mainImageVertical.jpg"
import MainImageVertical from "../assets/video/IMG_4928.MOV"
import MainImageHorizontal from "../assets/image/mainImageHorizontal.jpg"
import {ShopContext} from "../context/shoppingContext";
import axios from "axios";

export default function Main() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        // Function to update the window width
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        };

        // Listen to the window resize event
        window.addEventListener('resize', handleWindowResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);



    // const imageUrl = windowWidth < 700 ? MainImageVertical : MainImageHorizontal;
    const fontWeight = windowWidth < 700 ? "400" : "600";
    return (
      <article className="main-container">
          {
              windowWidth < 700 ?
                    <video src={MainImageVertical}
                           autoPlay
                           loop
                           muted
                           playsInline
                    />
                  :
                  <>
                    <img src={MainImageHorizontal} alt="MainImage"/>
                    <div className="text-overlay" style={{fontFamily: 'EB Garamond', fontSize: "30px", fontWeight: fontWeight}}>UNIQUE ARTWORKS WITH LOVE AND SOUL</div>
                  </>
          }
      </article>
    );
}
