import {useState} from "react";

export default function AddVerticalVideo() {
    const [file, setFile] = useState(null);

    const handleFileChanges = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
    }
    const handleSave = () => {
        console.log("TEST");
        if (!file) {
            alert("Please select a file first.");
            return;
        }

        const formData = new FormData();
        formData.append('file', file);
        formData.append('method', "upload_verticalvideo_image");

        fetch('https://ksartbykristinaandrus.de/api/records', {
            method: 'POST',
            body: formData
        })
            .then(response => response.json())
            .then(data => {
                if (data.verticalVideo) {
                    alert("File uploaded successfully.");
                } else {
                    alert("Failed to upload file.");
                }
            })
            .catch(error => {
                console.error('Error uploading file:', error);
            });
    }
    return (
      <div className="container">
            <div className="row">
                <div className="col-12 d-fles justify-content-center">
                    <input type="file" onChange={handleFileChanges}/>
                    <button onClick={() => handleSave()}>Save</button>
                </div>
            </div>
      </div>
    );
}