import React, {useContext, useEffect, useState} from "react";
import {ShopContext} from "../context/shoppingContext";
import CartProductContainer from "../cart/components/CartProductContainer";
import ButtonCheckout from "../cart/components/ButtonCheckout";
import {useLanguage} from "../context/languageContext";
export default function Cart() {
    const { cartItems, total, cartIsEmpty } = useContext(ShopContext);
    const [message, setMessage] = useState("");
    const { strings } = useLanguage();

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);

        if (query.get("success")) {
            setMessage("Order placed! You will receive an email confirmation.");

        }

        if (query.get("canceled")) {
            setMessage("Order canceled -- continue to shop around and checkout when you're ready.");
        }
    }, []);
    const thStyle = {
        paddingBottom: 12,
        paddingLeft: 10,
        color: "#4d4c4c"
    };

    const textareaStyle = {
        width: "100%",
        maxWidth: 300,
        padding: 12,
        fontSize: 12,
        background: "transparent"
    };

    return message ? (
            <div className="container text-center" style={{minHeight: "50vh"}}>
                <h4>{message}</h4>
            </div>
        ) : (
          <div className="container" style={{minHeight: "50vh"}}>
              <h4 className="text-center" style={{paddingTop: "12px", paddingBottom: "12px", fontFamily: 'Teko', fontWeight: 600, color: "#4d4c4c"}}>CART</h4>
              <div className="d-flex justify-content-center align-items-center">
                  <div className="container d-flex justify-content-center align-items-center"
                       style={{ maxWidth: '885px', width: '100%'}}
                  >
                      {cartIsEmpty() &&
                          <h5
                              className="text-center"
                              style={{paddingTop: "12px", paddingBottom: "12px",
                                  color: "#4f4d4d", fontSize: "16px"}}>
                              {strings.cartIsEmpty}
                          </h5>
                      }
                      {
                          !cartIsEmpty() &&
                          <table width={"100%"}>
                              <thead>
                                  <tr>
                                      <th style={thStyle}>Product</th>
                                      <th className="text-end" style={thStyle}>Price</th>
                                  </tr>
                              </thead>
                              <tbody>
                              {cartItems.map((product) => (
                                  <CartProductContainer id={product.id} key={product.id}/>
                              ))}
                              </tbody>
                          </table>
                      }
                  </div>
              </div>
              <h5 className="text-center" style={{paddingTop: "12px", paddingBottom: "12px", color: "#4f4d4d", fontSize: "16px"}}>{strings.warnCart}</h5>
              <h5 className="text-center" style={{paddingTop: "12px", paddingBottom: "12px", color: "#4f4d4d", fontSize: "16px"}}>{strings.cartNotice1}</h5>
              <h5 className="text-center" style={{paddingTop: "12px", paddingBottom: "12px", color: "#4f4d4d", fontSize: "16px"}}>{strings.cartNotice2}</h5>
              <div className="container"
                   style={{width: '100%', paddingRight: "12px", marginTop: 12 }}>
                  <div className="row d-flex justify-content-between">
                      <div className="col-12 col-sm-6">
                          <p style={{fontSize: 12, fontWeight: "600"}}>{strings.addOrderNote}</p>
                          <textarea name="comment" id="comment" style={textareaStyle} placeholder={strings.textAreaPlaceHolderCart}></textarea>
                      </div>
                      <div className="col-12 col-sm-6 d-flex flex-column justify-content-end align-items-end">
                          <p className="text-end " style={{fontSize: 16, fontWeight: "600"}}>{strings.total} €{total}</p>
                          <div className="mt-2">
                            <ButtonCheckout />
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        );
}