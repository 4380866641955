import React from "react";
import 'ldrs/ring'

export default function LoadingComponent(){
    return (
        <div
            className="d-flex align-items-center justify-content-center"
            style={{ height: '100vh' }}
        >
            <l-tailspin size="40" stroke="5" speed="0.9" color="black"></l-tailspin>
        </div>
    );
}
