import {useEffect, useState} from "react";
import ProductTableContent from "../components/ProductTableContent";

export default function ProductList () {
    const [products, setProducts] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                // Send username and password to server for authentication
                const response = await fetch('https://ksartbykristinaandrus.de/api/records', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ method: "15" }),
                });
                const data = await response.json();
                setProducts(data);
            } catch (error) {
                console.error('Error:', error);
            }
        }
        fetchData();
    }, []);

    return (
        <div>
            {products.map((product, index) => (
                <ProductTableContent  product={product} key={index}/>
            ))}
        </div>
    );
}