import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

export default function VerticalVideos() {
    const [videos, setVideos] = useState([]);
    const navigate = useNavigate();
    const styles = [
        {
            width: "100%",
            height: "auto",
            marginBottom: "12px"
        },
        {
            position: "absolute",
            left: "47%",
            width: "auto",
            top: "20px"
        }
    ]

    useEffect(() => {
        try {
            fetch('https://ksartbykristinaandrus.de/api/records', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ method: "8" })
                })
                .then(response => response.json())
                .then(data => setVideos(data))
                .catch(error => console.error(error))
        } catch (e) {
            console.error('Error:', e);
        }
    }, []);

    const handleDragStart = (e, index) => {
        e.dataTransfer.setData('index', index);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };
    const handleDrop = (e, targetIndex) => {
        e.preventDefault();
        const sourceIndex = e.dataTransfer.getData('index');
        const updatedVideos = [...videos];
        const item = updatedVideos.splice(sourceIndex, 1)[0];
        updatedVideos.splice(targetIndex, 0, item);
        setVideos(updatedVideos);
    };

    const handleSaveChanges = () => {
        if (!window.confirm('Are you confirm changes?')) {
            return;
        }
        try {
            fetch('https://ksartbykristinaandrus.de/api/records', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({method: "9", videos: videos}),
            });
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleNavigate = (url) => {
        navigate(url);
    }
    return (
      <div className="row">
          {videos.map((item, index) => (
              <div key={index} className="col-12 col-md-6 col-lg-4 col-xl-3 d-flex justify-content-center" style={{marginTop: "20px"}}>
                  <video
                      src={item}
                      width={300}
                      controls
                      draggable
                      onDragStart={(e) => handleDragStart(e, index)}
                      onDragOver={handleDragOver}
                      onDrop={(e) => handleDrop(e, index)}
                  ></video>
              </div>
          ))}
          <div style={styles[1]}>
              <button onClick={() => handleSaveChanges()} >Save</button>
              <button onClick={() => handleNavigate("add-new-video")} >Add new</button>
          </div>
      </div>
    );
}