import "./Header.css";
import {Link} from "react-router-dom";
import { ShopContext } from "../context/shoppingContext";
import {useContext, useEffect, useState} from "react";
import logoBold from "../assets/image/logo-bold.png";
import axios from "axios";

function Header() {
    const { productsUpdate, cartIsEmpty, cartItems} = useContext(ShopContext);
    const [products, setProducts] = useState([]);
    const [cartIsEmty, setCartIsEmpty] = useState(true)
    useEffect(() => {
        if (cartItems.length > 0) {
            setCartIsEmpty(false)
        } else {
            setCartIsEmpty(true);
        }
    }, [cartItems]);

    useEffect(() => {

        if (!cartIsEmpty) {
            return
        }

        // if (products.length > 0) return;
        const fetchData = async () => {
            try {
                const response = await axios.post('https://ksartbykristinaandrus.de/api/records',
                    { method: "1"});
                setProducts(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (cartIsEmpty){
            productsUpdate(products)
        }
    }, [products]);

    return (
        <header>
            <div>
                <div className="row header">
                    <div className="col-4">
                    </div>
                    <div className="col-4 d-flex align-items-center justify-content-center" style={{marginTop: 5}}>
                        <Link to="/">
                            <img src={logoBold} alt="" width={140}/>
                        </Link> 
                    </div>
                    {/* eslint-disable-next-line react/style-prop-object */}
                    <div className="col-4 text-end" style={{padding: 1 + 'em'}} >
                        <Link to="/cart" >
                            {
                                cartIsEmty &&
                                <svg style={{color: "black", marginTop: "18px"}}
                                    xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-bag" viewBox="0 0 16 16">
                                    <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5z"/>
                                </svg>
                            }
                            {
                                !cartIsEmty &&
                                <svg
                                    style={{color: "black", marginTop: "18px"}}
                                    xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-bag-check-fill" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M10.5 3.5a2.5 2.5 0 0 0-5 0V4h5v-.5zm1 0V4H15v10a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4h3.5v-.5a3.5 3.5 0 1 1 7 0zm-.646 5.354a.5.5 0 0 0-.708-.708L7.5 10.793 6.354 9.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3z"/>
                                </svg>
                            }
                        </Link>
                    </div>
                </div>
            </div>

        </header>
    )
}

export default Header;