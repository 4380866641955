
export default function ToggleButton({isChecked, handleToggleChange}) {
    return (
        <>
        <label htmlFor="toggle" style={{
            pointerEvents: "none"
        }}>
            <input
                className={`input  ${isChecked ? 'checked' : ''}`}
                type="checkbox"
                id="toggle"
                checked={isChecked}
                onChange={handleToggleChange}
                style={{
                    display: "none"
                }}
            />
            <div className="toggle-wrapper" style={{
                position: "relative",
                width: 60,
                height: 30,
                backgroundColor: "#eaeaea",
                borderRadius: 999,
                margin: "auto",
                cursor: "pointer",
                pointerEvents: "all",
                boxShadow: "0 8px 14px 0 rgba(0, 0, 0, 0.3)"
            }}>
                <span className={`selector`} style={{
                    width: 20,
                    height: 20,
                    position: "absolute",
                    top: "50%",
                    transform: "translateY(-50%)",
                    backgroundColor: isChecked ? "rgb(162,130,130)" : "rgb(72,62,62)",
                    transition: "left 0.25s ease",
                    borderRadius: "50%",
                    left: isChecked ? 'calc(100% - 30px)' : '10px'
                }}></span>
            </div>
        </label>
        </>
    );
}