import React, { createContext, useState, useEffect } from 'react';

const AuthContext = createContext(null);

const AuthProvider = (props) => {
    const [isAuth, setIsAuth] = useState(() => {
        // Check if isAuth is stored in localStorage
        const storedIsAuth = localStorage.getItem('isAuth');
        return storedIsAuth ? JSON.parse(storedIsAuth) : false;
    });

    useEffect(() => {
        // Update localStorage when isAuth changes
        try {
            localStorage.setItem('isAuth', JSON.stringify(isAuth));
        } catch (error) {
            console.error('Error storing isAuth in localStorage:', error);
        }
    }, [isAuth]);

// Set isAuth to false after 30 seconds of user inactivity
    useEffect(() => {
        const timerTime = 300000;
        let timeout = setTimeout(() => {
            logout(); // Call logout when timeout occurs
        }, timerTime);

        // Reset timeout if the user becomes active again
        const resetTimeout = () => {
            clearTimeout(timeout);
            // Set new timeout
            timeout = setTimeout(() => {
                logout(); // Call logout when timeout occurs
            }, timerTime);
        };

        window.addEventListener('mousemove', resetTimeout);
        window.addEventListener('keypress', resetTimeout);

        return () => {
            window.removeEventListener('mousemove', resetTimeout);
            window.removeEventListener('keypress', resetTimeout);
            clearTimeout(timeout);
        };
    }, [isAuth]);

    const login = () => {
        setIsAuth(true);
    };

    const logout = () => {
        setIsAuth(false);
        try {
            localStorage.setItem('isAuth', JSON.stringify(false)); // Update localStorage on logout
        } catch (error) {
            console.error('Error updating isAuth in localStorage on logout:', error);
        }
    };

    return (
        <AuthContext.Provider value={{ isAuth, login, logout }}>
            {props.children}
        </AuthContext.Provider>
    );
};

export { AuthProvider, AuthContext };
