import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {useLanguage} from "../context/languageContext";
import ReactMarkdown from "react-markdown";

export default function Legal(){
    const { strings } = useLanguage();
    const { title } = useParams();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        // Function to update the window width
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        };

        // Listen to the window resize event
        window.addEventListener('resize', handleWindowResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);
    const divStyle = {
        padding: windowWidth > 768 ? "0 15%" : "0 0",
    };

    return (
        <section className="container" >
            {
                title === "privacyPlicy" &&
                <div style={divStyle}>
                    <h2 className="text-center">{strings.privacyPolicyTitle}</h2>
                    <ReactMarkdown
                        components={{
                            p: ({node, ...props}) => <p style={{ fontFamily: 'Teko, sans-serif', color: "#4d4c4c", fontSize: "14px" }} {...props} />
                        }}>{strings.privacyPolicy}</ReactMarkdown>
                </div>
            }
            {
                title === "termOfService" &&
                <div style={divStyle}>
                    <h2 className="text-center">{strings.termOfServiceTitle}</h2>
                    <ReactMarkdown
                        components={{
                        p: ({node, ...props}) => <p style={{ fontFamily: 'Teko, sans-serif', color: "#4d4c4c", fontSize: "14px" }} {...props} />
                    }}>{strings.termOfService}</ReactMarkdown>
                </div>
            }
            {
                title === "cookiePolicy" &&
                <div style={divStyle}>
                    <h2 className="text-center">{strings.cookiePolicyTitle}</h2>
                    <ReactMarkdown
                        components={{
                            p: ({node, ...props}) => <p style={{ fontFamily: 'Teko, sans-serif', color: "#4d4c4c", fontSize: "14px" }} {...props} />
                        }}>{strings.cookiePolicy}</ReactMarkdown>
                </div>
            }
            {
                title === "refund" &&
                <div style={divStyle}>
                    <h2 className="text-center">{strings.refundTitle}</h2>
                    <ReactMarkdown
                        components={{
                            p: ({node, ...props}) => <p style={{ fontFamily: 'Teko, sans-serif', color: "#4d4c4c", fontSize: "14px" }} {...props} />
                        }}>{strings.refund}</ReactMarkdown>
                </div>
            }
            {
                title === "shippingPolicy" &&
                <div style={divStyle}>
                    <h2 className="text-center">{strings.shippingPolicyTitle}</h2>
                    <ReactMarkdown
                        components={{
                            p: ({node, ...props}) => <p style={{ fontFamily: 'Teko, sans-serif', color: "#4d4c4c", fontSize: "14px" }} {...props} />
                        }}>{strings.shippingPolicy}</ReactMarkdown>
                </div>
            }
        </section>
    );
}