import {useState} from "react";

export default function ImageItem({link, image, index, onDragStart, handleDragOver, onDrop, removeImage}) {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <div style={{ position: 'relative', display: 'inline-block' }}>
            <div
                style={{ position: 'relative', overflow: 'hidden' }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
            <img key={index} src={link} alt={`Preview ${index}`} style={{
                display: 'block',
                width: 200,
                height: 200,
                transition: 'transform 0.3s',
                transform: isHovered ? 'scale(1.1)' : 'scale(1)',
                objectFit: 'cover', margin: '5px'
            }} draggable
                 onDragStart={onDragStart}
                 onDragOver={handleDragOver}
                 onDrop={onDrop} onDoubleClick={() => removeImage(image)}/>
            </div>
        </div>
    )
}