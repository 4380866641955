import './App.css';
import { BrowserRouter as Router, Routes, Route} from "react-router-dom"
import Cart from "./pages/Cart";
import Ksart from "./pages/Ksart";
import {ShopContextProvider} from "./context/shoppingContext";
import { LanguageProvider } from "./context/languageContext";
import Store from "./pages/Store";
import ProductDetails from "./pages/ProductDetails";
import { tailspin } from 'ldrs'
import React from "react";
import Legal from "./pages/Legal";
import AdminPanel from "./admin/components/AdminPanel";
import GalleryDetails from "./admin/details/GalleryDetails";
import AddVerticalVideo from "./admin/addition/AddVerticalVideo";
import {AuthProvider} from "./admin/authorization/AuthContext";
import UserApp from "./pages/UserApp";
import AdminApp from "./admin/pages/AdminApp";
import AdminProductDetails from "./admin/details/AdminProductDetails";

tailspin.register()

function App() {
    // rgb(238,230,218)\rgb(243,234,227)
  return (
    <div className="App" style={{background: "rgb(232,229,227)"}}>
        <ShopContextProvider>
            <LanguageProvider>
                <Router>
                    <Routes>
                        <Route path="/" element={<UserApp />}>
                            <Route index element={<Ksart />} />
                            <Route path="/cart" element={<Cart />} />
                            <Route path="/store" element={<Store />} />
                            <Route path="/product/:id" element={<ProductDetails />} />
                            <Route path="/legal/:title" element={<Legal />} />
                        </Route>
                    </Routes>
                </Router>
            </LanguageProvider>
            <AuthProvider>
                <Router>
                    <Routes>
                        <Route path="/admin-panel" element={<AdminApp />}>
                            <Route index element={<AdminPanel />} />
                            <Route path="gallery-item/:id" element={<GalleryDetails />} />
                            <Route path="add-new-video" element={<AddVerticalVideo />} />
                            <Route path="product-item/:id" element={<AdminProductDetails />} />
                        </Route>
                    </Routes>
                </Router>
            </AuthProvider>
        </ShopContextProvider>
    </div>
  );
}
export default App;
