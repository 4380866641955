import {useState} from "react";
import ProductList from "../pages/ProductList";
import Gallery from "../pages/Gallery";
import VerticalVideos from "../pages/VerticalVideos";
import AddProductItem from "../details/AddProductItem";

export default function Dashboard({ logout }) {
    const [isOpenMenu, setIsOpenMenu] = useState(false);
    const [currentSection, setCurrentSection] = useState("product_list");
    const styles = [
        {
            backgroundColor: "#348181",
            fontWeight: "500",
            padding: 12
        },
        {
            position: "absolute",
            backgroundColor: "#FFFFFF",
            width: "90%",
            marginTop: "5px",
            zIndex: "10"
        },
        {
            borderBottom: "solid 1px black",
            padding: "16px"
        }
    ]

    const onClickMenuOption = (title) => {
        setIsOpenMenu(false);
        setCurrentSection(title);
    }

    const menuOptions = [
        { label: 'Product List', section: 'product_list' },
        { label: 'Gallery', section: 'gallery' },
        { label: 'Vertical Videos', section: 'vertical_videos' },
        { label: 'Add Product', section: 'add_product_item' },
    ];
    return (
      <section className="container">
          <div className="row" style={{marginTop: "20px"}}>
              <button style={styles[0]} className="col-3 col-md-2"
                      onClick={() => logout()}>
                  Sign Out
              </button>
              <div className="col-6 col-md-8"></div>
              <button style={styles[0]} className="col-3 col-md-2"
                      onClick={() => setIsOpenMenu(!isOpenMenu)}>
                  Menu
              </button>
          </div>
          <div>
              {isOpenMenu && (
                  <div style={styles[1]}>
                      {menuOptions.map((option) => (
                          <div
                              key={option.section}
                              style={styles[2]}
                              onClick={() => onClickMenuOption(option.section)}
                          >
                              {option.label}
                          </div>
                      ))}
                  </div>
              )}
              {currentSection === 'product_list' && <ProductList />}
              {currentSection === 'gallery' && <Gallery />}
              {currentSection === 'vertical_videos' && <VerticalVideos />}
              {currentSection === 'add_product_item' && <AddProductItem />}
          </div>
      </section>
    );
}