// import VerticalVideoPlayer from "./components/VerticalVideoPlayer";
import VideoComponent from "./components/VideoComponent";
import {useEffect, useRef, useState} from "react";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import axios from "axios";
export default function VerticalVideo() {
    // const videos = [
    //     "https://ksartbykristinaandrus.de/videos/IMG_1944.MOV",
    //     "https://ksartbykristinaandrus.de/videos/IMG_9936.MOV",
    //     "https://ksartbykristinaandrus.de/videos/IMG_6641.MOV",
    //     "https://ksartbykristinaandrus.de/videos/IMG_6754.MOV",
    //     "https://ksartbykristinaandrus.de/videos/IMG_6756.MOV",
    //     "https://ksartbykristinaandrus.de/videos/IMG_6642.MOV",
    //     "https://ksartbykristinaandrus.de/videos/IMG_6612.MOV",
    //     "https://ksartbykristinaandrus.de/videos/IMG_6611.MOV",
    // ]
    const [videos, setVideos] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.post('https://ksartbykristinaandrus.de/api/records',
                    { method: "5"});
                setVideos(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);
    const carouselRef = useRef();
    const [orderNumber, setOrderNumber] = useState(0);

    const changeOrderNumber = (index) => {
        if (index === videos.length){
            index = 0
        }
        setOrderNumber(index);
    };
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    // Function to update screenWidth state
    const updateScreenWidth = () => {
        setScreenWidth(window.innerWidth);
    };

    useEffect(() => {
        // Event listener to update screenWidth on resize
        window.addEventListener('resize', updateScreenWidth);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', updateScreenWidth);
        };
    }, []);

    const items = [5, 4, 3, 2, 1];
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: items[0],
            slidesToSlide: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: items[1],
            slidesToSlide: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 700 },
            items: items[2],
            slidesToSlide: 1
        },
        mobile: {
            breakpoint: { max: 700, min: 450 },
            items: items[3],
            slidesToSlide: 1
        },
        small: {
            breakpoint: { max: 450, min: 100 },
            items: items[4],
            slidesToSlide: 1
        }
    };

    // useEffect(() => {
    //     console.log("OrderNumber: " + orderNumber + " CurrentSlide: " + carouselRef.current.state.currentSlide)
    //     if (carouselRef.current && orderNumber !== undefined) {
    //         const breakpoints = [3000, 1024, 700, 450, 100];
    //         const slidesToAdvance = [5, 4, 3, 2, 1];
    //
    //
    //         breakpoints.some((breakpoint, index) => {
    //             const visibilityRange = carouselRef.current.state.currentSlide + slidesToAdvance[index];
    //             if (carouselRef.current.state.currentSlide <= orderNumber < visibilityRange) {
    //                 setOrderNumber(carouselRef.current.state.currentSlide)
    //             }
    //         });
    //     }
    // }, [carouselRef.current.state.currentSlide]);

    useEffect(() => {
        // console.log("OrderNumber: " + orderNumber + " CurrentSlide: " + carouselRef.current.state.currentSlide)
        // if (carouselRef.current && orderNumber !== undefined) {
        //     carouselRef.current.goToSlide(orderNumber, true);
        // }
        if (carouselRef.current && orderNumber !== undefined) {
            const breakpoints = [3000, 1024, 700, 450, 100];
            const slidesToAdvance = [5, 4, 3, 2, 1];

            let shouldAdvanceSlide = false;

            breakpoints.some((breakpoint, index) => {
                if (screenWidth > breakpoint) {
                    if (orderNumber >= slidesToAdvance[index]) {
                        shouldAdvanceSlide = true;
                        return true;
                    } else if (orderNumber === 0) {
                        carouselRef.current.goToSlide(orderNumber, true);
                        return true;
                    } else {
                        shouldAdvanceSlide = false;
                        return true;
                    }
                }
                return false;
            });

            if (shouldAdvanceSlide) {
                carouselRef.current.goToSlide(carouselRef.current.state.currentSlide + 1, true);
            }
        }
    }, [orderNumber]);
    return (
        <div className="vertical-video-container" style={{width: 95 + "%", paddingLeft: 5 + "%", marginTop: 40, marginBottom: 40}}>
            <Carousel
                ref={carouselRef}
                // infinite={true}
                responsive={responsive}
                swipeable={true}
                draggable={true}
            >
                {videos.map((item, index) => (
                    <VideoComponent url={item.video} index={index} key={index} changeOrderNumber={changeOrderNumber} play={index === orderNumber} />
                ))}
            </Carousel>
        </div>
    );
}